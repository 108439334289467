import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {

  langArray:any = ['en','pt','es'];
  selectedLang = 'pt';
  @Output() onLoad: EventEmitter;
  @Output() onStart: EventEmitter;
  @Output() onFinish: EventEmitter;


  constructor(public helper:HelperService) { }

  ngOnInit() {
  }

  changeLang(lang){
    this.selectedLang = lang;
    switch(this.selectedLang){
      case 'pt':
        this.langArray = ['en','pt','es'];
        break;
      case 'es':
        this.langArray = ['en','es','pt'];
        break;
      case 'en':
        this.langArray = ['pt','en','es'];
        break;
    }
  }
}

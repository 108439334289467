import { Component, OnInit } from '@angular/core';
import { ContentComponent } from '../../components/content/content.component';
import { ApiService } from 'src/app/services/api.service';


@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    content: any = {};
    editors: any = [];
    illustrators: any = [];

    constructor(
        public apiService: ApiService
    ) {
        this.apiService._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/sobre/KBI66U79EW.json').then((r) => {
            this.content = r;
        })
    }

    ngOnInit() {
        this.getAuthors();
    }
    getAuthors() {

        this.apiService._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-concerto/ficha-tecnica/list.json').then((r: any) => {
            console.log(r.rows);

            r.rows.forEach((el, idx) => {
                el['foto-de-perfil-url'] = el['foto-de-perfil-url'].split('?')[0];

                if (el['selecione-um-tipo-de-perfil-do-autor'] == 'artigo' || (el['selecione-um-tipo-de-perfil-do-autor'].indexOf('{{option}.value}') >= 0 && idx % 2 == 0)) {
                    this.editors.push(el);
                } else {
                    this.illustrators.push(el);
                }
                // if (el['selecione-um-tipo-de-perfil-do-autor'] == 'ilustra') {
                // this.editors.push(el);
                // }
                // if (contentData['autor-texto'] == el.id) {
                //     this.text_author = el;
                //     this.text_author['foto-de-perfil-url'] = this.text_author['foto-de-perfil-url'].split('?')[0];
                //     console.log('text_author', el);
                // }
                // if (contentData['autor-ilustracao'] == el.id) {
                //     this.illustration_author = el;
                //     this.illustration_author['foto-de-perfil-url'] = this.illustration_author['foto-de-perfil-url'].split('?')[0];
                //     console.log('illustration_author', el);
                // }
            });
            console.log('editors', this.editors);
            console.log('illustrators', this.illustrators);

        });
    }
}

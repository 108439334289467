import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';
import { BannerComponent } from './components/banner/banner.component';
import { ShareComponent } from './components/share/share.component';
import { HomeComponent } from './views/home/home.component';
import { DetailsComponent } from './views/details/details.component';
import { BoxComponent } from './components/box/box.component';
import { PlayerComponent } from './components/player/player.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AboutComponent } from './views/about/about.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageComponent } from './components/image/image.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CtaComponent } from './components/cta/cta.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
// import { PinchZoomModule } from 'ngx-pinch-zoom';
import { DownloadComponent } from './components/download/download.component';


@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        ContentComponent,
        BannerComponent,
        HomeComponent,
        DetailsComponent,
        BoxComponent,
        PlayerComponent,
        LoaderComponent,
        AboutComponent,
        ImageComponent,
        ToolbarComponent,
        SidenavComponent,
        ShareComponent,
        CtaComponent,
        BreadcrumbComponent,
        AudioPlayerComponent,
        DownloadComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        // PinchZoomModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';


@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit, OnDestroy {
    public modalContent: any = false;
    public subscribedRoute;
    public content: any = {};
    public text_author: any = {};
    public illustration_author: any = {};
    public items: any = [];
    public loaded:boolean = false;

    public mock: any = {
        'id': 1,
        'title_br': 'PIANISTAS E PIANEIROS:',
        'subtitle_br': 'DE CHOPIN A HERCULES GOMES',
        'author_name': 'Camila Fresca',
        'created': 'XX/XX/XXXX',
        'featured': '../../../assets/images/details-banner.jpg',
        'thumb': '../../../assets/images/thumb-article.png',
        'short_content_br': `A Mauris in convallis sapien, ut
        hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
        eleifend sed erat non elementum.`,
        'content_br_1': ``,
        video: {
            id: 11385,
            title_br: 'TÍTULO DO VÍDEO SOBRE O ARTÍSTA',
            description: 'Pellentesque ligula ligula, Lorem ipsum ',
            thumb: 'https://d14xsdnyr8d6ih.cloudfront.net/content/thumbnail/de5db2c8-c42b-460a-819a-837ce4a0351d-2017-03-10T17-35-58-937Z',
            fileId: "14084160d1e6cbb8364b2c1cf42ad0ad"
        },
        playlistApple: 'nós-mulheres/pl.ebd84d29749741cbb7ae0a814aa98716',
        playlistSpotify: '7sUY1RU6KHRQ3saFYvLO5o',
        playlistDeezer: '5099088468',
        images: [{
            image: '../../../assets/images/details-image1.jpg',
            text: 'Gravura: Rafael Suzuki'
        }, {
            image: '../../../assets/images/details-image2.jpg',
            text: 'Fragmento de gravura: Rafael Suzuki'
        }, {
            image: '../../../assets/images/details-image3.jpg',
            text: 'Fragmento de gravura: Rafael Suzuki'
        }],
        relateds: [
            {
                'id': 1,
                'title_br': 'TÍTULO EXTENSO DA MATÉRIA DA PLAYLIST DE DUAS',
                'author_name': 'Camila Fresca'
            },
            {
                'id': 2,
                'title_br': 'TÍTULO MÉDIO DA MATÉRIA SOBRE A PLAYLIST',
                'author_name': 'Nome Sobrenome'
            },
            {
                'id': 3,
                'title_br': 'TÍTULO EXTENSO DA MATÉRIA DA PLAYLIST DE DUAS',
                'author_name': 'Nome Sobrenome'
            }
        ]
    };
    public readers: any[];

    constructor(
        public activatedRoute: ActivatedRoute,
        public api: ApiService,
        public router: Router,
        public helper: HelperService
    ) {
        this.subscribedRoute = this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                this.loadData();
            }
        })
    }


    ngOnDestroy() {
        this.subscribedRoute.unsubscribe();
    }


    ngOnInit() {
    }


    loadData() {
        let path = this.activatedRoute.snapshot['_routerState'].url.split('/');
        path.shift(); // remove primeira '/'
        path.shift(); // remove 'conteudo'

        this.getItems().then(() => {
            this.items.authors.forEach(item => {
                let slug = this.helper.slugify(item['nome-leitor_br'] + '/' + item['nome-autor_br']);
                if (slug == path[0]) {
                    this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/autor/'+item.id+'.json').then((rData: any) => {
                        console.log(rData);
                        this.content = rData;
                        this.loaded = true;
                    });
                }
            });
            this.items.readers.forEach((item, idx) => {
                let slug = this.helper.slugify(item['nome-leitor_br'] + '/' + item['nome-autor_br']);
                if (slug == path[0]) {
                    this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/leitor/'+item.id+'.json').then((rData: any) => {
                        console.log(idx, rData);
                        this.content = rData;
                        this.loaded = true;
                    });
                }
            });
        });

    }


    getItems() {
        return new Promise((resolve, reject) => {
            this.getAuthors().then((r: any) => {
                this.items.authors = r.rows;
                this.getReaders().then((r: any) => {
                    this.items.readers = r.rows
                    resolve();
                });
            });
        })
    }
    sidenavSelect(item){
        // console.log(ev);
        let slug = this.helper.slugify(item['nome-leitor_br'] + '/' + item['nome-autor_br']);
        this.router.navigate(['conteudo/' + slug]);

    }
    getAuthors() {
        return this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/autor/list.json');
    }
    getReaders() {
        return this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/leitor/list.json');
    }

}

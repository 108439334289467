import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
    @Input('playlist') playlist: any = {};
    @Input('title') title: any = 'Pellentesque ligula ligula, Lorem ipsum dolor sit amet';

    public urlSpotify;
    public urlDeezer;
    public urlApple;
    public player: any;

    constructor(public sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (this.playlist.spotify) {
            this.urlSpotify = this.sanitizeUrl('https://open.spotify.com/embed/playlist/' + this.playlist.spotify);
        }
        if (this.playlist.deezer) {
            this.urlDeezer = this.sanitizeUrl('https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=true&width=700&height=350&color=ff0000&layout=&size=medium&type=playlist&id=' + this.playlist.deezer+'&app_id=1');
        }
        if (this.playlist.apple) {
            this.urlApple = this.sanitizeUrl('https://embed.music.apple.com/us/playlist/' + this.playlist.apple + '?app=music');
        }

    }

    play(player) {
        if (this.player == player) {
            this.player = "";
        } else {
            this.player = player;
        }
    }

    sanitizeUrl(url) {
        let sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log(sanitized);
        return sanitized;
    }

}

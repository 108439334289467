import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, SimpleChanges, OnChanges } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges {

    @Input('background') background:string;
    @Input('src') src:string;
    @Input('canvas') canvas:boolean = false;
    @Input('text') text:any;
    @Input('max-height') maxHeight:any;

    @Input('fullscreen') fullscreen:boolean = false;
    @ViewChild('image') image:ElementRef;
    private canvasImage: HTMLImageElement = new Image();
    private top: number;
    private bottom: number;
    private left: number;
    private right: number;
    private centerX: number;
    private centerY: number;
    private zoomLevel: number = 0;
    private maxZoom: number = 1;
    private canvasWidth: number;
    private canvasHeight: number;

  constructor(
    public helper: HelperService,
    private renderer: Renderer2,
    public el: ElementRef

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['background'] && changes['background'].currentValue){
      if(changes['background'].currentValue.indexOf('://') > 0 || changes['background'].currentValue.indexOf('assets/') === 0 ){
        this.background = changes['background'].currentValue;
      }else{
        this.background = environment.URL_MEDIA + changes['background'].currentValue;
      }
    }
    if(changes['src'] && changes['src'].currentValue){
        this.canvasImage.src = this.src;
    }

  }
  ngOnInit() {
    if(this.background){
      let bg = this.background;
      let el = this.el.nativeElement;
      // console.log(bg,el);
      this.renderer.addClass(el,'background');
      this.renderer.setStyle(el, 'background-image', 'url(' + bg + ')');
    }
  }

  toggleFullscreen(){
      this.fullscreen = !this.fullscreen;
      this.zoomLevel = 0;
  }

}

import { Component, OnInit, Input } from '@angular/core';
// import { VarsService } from '../../services/vars.service';
import { Router, NavigationEnd } from '@angular/router';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input('active-content') activeContent: any = false;

    public path: any = [];
    public subscribedRoute: any;

    constructor(
        public helper: HelperService,
        public router: Router
    ) {
    }
    ngOnInit() {
        this.activeContent = this.activeContent.toLocaleLowerCase();
    }
}

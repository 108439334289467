import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
// // import { BannerComponent } from '../banner/banner.component';
// // import { ImageComponent } from '../image/image.component';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public loaded: boolean = false;
    public items = { authors: [], readers: [] };
    public activeItemMenu = 'readers';
    public cover: any = {};
    public selectedItem: number = 0;
    public preselectedItems: any = [];

    // @HostListener('scroll', ['$event']) private onScroll($event:Event):void {
    //   };

    constructor(
        public helper: HelperService,
        public api: ApiService,
        public router: Router
    ) { }

    ngOnInit() {
        this.loaded = true;
        this.helper.firstLoad = false;
        this.getCover();
        this.getAuthors();
        this.getReaders();
    }

    setInitialScroll() {
        const scroll = document.getElementById('scroller');
        setTimeout(() => {
            scroll.scrollTo(0, 5);
        }, 500);
    }

    scroll(dir){
        if(this.selectedItem <= 0 && dir < 0){
            return false;
        }
        if(this.selectedItem >= this.items[this.activeItemMenu].length - 1 && dir > 0){
            return false;
        }
        this.selectedItem += dir;
        let scroller = document.getElementById('scroller');
        scroller.scrollTop = scroller.children[0]['offsetHeight'] * this.selectedItem;
    }
    onScroll(ev){
        const scroll = document.getElementById('scroller');
        const scrollHeight = scroll.scrollHeight - 485;
        let scrollTop = ev.srcElement['scrollTop'];
        let itemHeight = ev.srcElement.children[0].offsetHeight;
        let itemsScrolled = Math.round(scrollTop / itemHeight);
        this.selectedItem = itemsScrolled;
        this.preselectedItems = [itemsScrolled-1,itemsScrolled+1];

        if (this.items[this.activeItemMenu].length > 4) {
            if (scrollTop == 0) {
                scroll.scrollTo(0, scrollHeight - 5);
            } else if (scrollHeight == scrollTop) {
                scroll.scrollTo(0, 5);
            }
        }
    }
    getCover() {
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/capa/KBI6F1LGNQ.json').then((r: any) => {
            this.cover = r;
        });
    }
    getAuthors() {
        this.api._get(' https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/autor/list.json').then((r: any) => {
            this.items.authors = this.sortByName(r.rows)
            this.setInitialScroll();
        });
    }
    getReaders() {
        this.api._get(' https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/leitores/leitor/list.json').then((r: any) => {
            this.items.readers = this.sortByName(r.rows)
            this.setInitialScroll();
        });
    }

    sortByName(list) {
        const items = list.sort((a, b) => {
            if (a['nome-leitor_br'] > b['nome-leitor_br']) {
              return 1;
            }
            if (a['nome-leitor_br'] < b['nome-leitor_br']) {
              return -1;
            }
            return 0;
        });
        return items;
    }

    viewDetails(item) {
        let slug = this.helper.slugify(item['nome-leitor_br'] + '/' + item['nome-autor_br']);
        this.router.navigate(['conteudo/' + slug]);
    }

    getItemIndex(index) {
        if (this.items[this.activeItemMenu].length) {
            if (Math.sign(index) == -1) {
                const item = this.items[this.activeItemMenu][this.items[this.activeItemMenu].length + index];
                return item;
            } else {
                const item = this.items[this.activeItemMenu][index];
                return item;
            }
        }

        return null;
    }
}

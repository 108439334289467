import { Component, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HelperService } from './services/helper.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'leitores';
    public hasFooter: boolean = false;
    public hasHeader: boolean = false;

    @HostBinding('class') private navClass: string;

    constructor(
        public router: Router,
        public helper: HelperService
    ) {
        console.log('v0.0.1');
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.hasFooter = this.router.url.split('/')[1] !== 'home';
                this.hasHeader = this.router.url.split('/')[1] !== 'home';
                this.navClass = 'pg-' + this.router.url.split('/')[1];
                window.scrollTo(0, 0);
            }
        });
    }
}

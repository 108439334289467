import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../../services/translate.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isMobileActive:boolean = false;
  public activeSubmenu:number = -1;
  public menu:any = [{
    label_br: 'sobre',
    href: 'sobre',
    class: '',
    submenus:[
      {label_br:'Estações do Corpo e Espírito',href:'sobre/estacoes-do-corpo-e-espirito'},
      {label_br:'A linguagem expressiva dos corpos',href:'sobre/a-linguagem-expressiva-dos-corpos'},
      {label_br:'Corpo Vivo',href:'sobre/corpo-vivo'}
    ]
  },{
    label_br: 'encontros com abramovic',
    href: 'encontros-com-abramovic',
    class: '',
    submenus:[
      {label_br:'Milágrimas',href:'espetaculos/34728'},
      {label_br:'Samwaad',href:'espetaculos/34720'},
    ]
  },{
    label_br: 'oito performance',
    href: 'oito-performance',
    class: '',
    submenus:[
      {label_br:'Infinito Movimento',href:'trilogia-poetica/34736'},
      {label_br:'Alcance dos Sentidos',href:'trilogia-poetica/34743'},
      {label_br:'Próximo Passo',href:'trilogia-poetica/36427'}
      // {label_br:'Olhar o vento',href:'trilogia-poetica/5'}
    ]
  },{
    label_br: 'galeria de fotos',
    href: 'galeria-de-fotos',
    class: '',
    submenus:[
      {label_br:'Fases da Vida',href:'webseries/34685'},
      {label_br:'Qualidade de Vida',href:'webseries/34717'}
    ]
  },{
    label_br: 'artigos',
    href: 'artigos',
    class: ''
  },{
    label_br: 'loja',
    href: 'loja',
    class: ''
  }];

  constructor(
    public translate: TranslateService,
    public router: Router,
  ) { }

  ngOnInit() {
  }
  goHome(){
      this.router.navigate(['home']);
  }
  toggleMobile(){
    this.isMobileActive = !this.isMobileActive;
  }

}

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {

  @Input('item') item:any = {};

  @Input('image') image:string;
  @Input('title') title:string;
  @Input('content') content:string;

  @Output() private onClick = new EventEmitter;

  constructor(public helper:HelperService) { }

  ngOnInit() {
      console.log(this.item);
  }
  emitClick(){
    let item:any = {
      image:this.image,
      title:this.title,
      content:this.content,
    };
    this.onClick.emit(item);
  }
}

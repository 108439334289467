import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
    @Input() mobile = false;
    @Input() theme = 'light';
    visible: boolean = false;
    constructor(public helper:HelperService) { }

    ngOnInit() {
    }

    openModal() {
        this.helper.shareVisible = true;
        this.visible = true;
        // let dom: any = document.querySelector('.modal-share');
        // dom.className += ' show';
    }
    closeModal(){
        this.helper.shareVisible = false;
        this.visible = false;
        // let dom: any = document.querySelector('.modal-share');
        // dom.className =  dom.className.replace(' show','');

    }

    isDarkMode(){
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    share(social) {
        const url = location.href;
        let popupWidth = 300;
        let popupHeight = 350;
        let topOffset = (window.innerHeight - popupHeight) / 2;
        let leftOffset = (window.innerWidth - popupWidth) / 2;

        switch (social) {
            case 'facebook':
                window.open('https://www.facebook.com/sharer.php?u=' + url + '&display=popup', document.title, 'height=' + popupHeight + ',width=' + popupWidth + ',top=' + topOffset + ',left=' + leftOffset);
                break;
            case 'whatsapp':
                window.open('https://wa.me/?text='+document.title +' ' + url);
                break;
            case 'twitter':
                window.open('https://twitter.com/share?url='+url+'&text='+document.title, document.title, 'height=' + popupHeight + ',width=' + popupWidth + ',top=' + topOffset + ',left=' + leftOffset);
                break;
            case 'email':
                window.open('mailto:?subject=' + document.title + '&body='+document.title+': '+ url +'" title="Compartilhado por email');
                break;
            case 'pinterest':
                window.open('https://pinterest.com/pin/create/bookmarklet/?url='+url+'&description='+document.title);
                break;
            default:
                break;
        }
    }

}

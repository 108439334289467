import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AboutComponent } from './views/about/about.component';
import { DetailsComponent } from './views/details/details.component';

const routes: Routes = [{
    path: "",
    redirectTo: '/home',
    pathMatch: 'full'
}, {
    path: 'home',
    component: HomeComponent
}, {
    path: 'sobre',
    component: AboutComponent
}, {
    path: 'conteudo/:slug',
    component: DetailsComponent
}, {
    path: '**',
    redirectTo: '/home'
}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

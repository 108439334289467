import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { componentHostSyntheticProperty } from '@angular/core/src/render3';

@Component({
    selector: 'app-cta',
    templateUrl: './cta.component.html',
    styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {
    @Input() template: string = 'default';
    @Input() id: any;
    @Input() title: any;

    constructor(
        public router: Router,
        public helperService: HelperService
    ) { }

    ngOnInit() {
    }

    setContentId(){
        this.helperService.currentId =  this.id;
    }
    navigateToUrl() {
        if (!this.title) return '';
        // if (!this.id) return '';
        let path = '/conteudo/'+ this.helperService.slugify(this.title);
        // console.log(path);
        // this.router.navigate([path]);
        return path;
        // return '/conteudo/' + this.id + '/' + this.helperService.slugify(this.title);
    }

}

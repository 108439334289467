import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
    @Input('thumb') thumb: string;
    @Input('filepath') filepath: string;

    constructor() { }

    ngOnInit() {
    }

}
